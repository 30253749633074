<template>
  <div>
    <div class="container">
      <div
        class="row justify-content-center align-items-center text-center vh-100"
      >
        <div class="col-md-12">
          <h1>404</h1>
          <h3>Oops! Page not found.</h3>
          <h5>We couldn't find the page you're looking for.</h5>
          <router-link
            :to="{ name: 'Home' }"
            class="btn btn-main rounded-pill py-2 mt-4 px-5 font-semibold"
            >Back to Home</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style scoped>
h1 {
  font-size: 5rem;
  color: #101010;
}

h5 {
  color: #10101066;
}
</style>